*::after,
*::before,
* {
  box-sizing: border-box;
}

.Logo {
  position: absolute;
  left: 70px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  top: 150px;
  cursor: pointer;
}

.fa-building {
  position: absolute;
  top: 156px;
  color: rgba(146, 152, 166, 1);
  font-size: 18px;
  left: 180px;
}


.organisation {
  position: absolute;
  top: 135px;
  left: 203px;
  font-size: 13px;
  letter-spacing: 1px;
  color: #9298A6;
  font-family: 'Franklin  Medium', 'Arial Narrow', Arial, sans-serif;
}

.Tegetatitle {
  position: absolute;
  left: 178px;
  top: 173px;
  letter-spacing: 2px;
  font-family: Avenir Next Georgian;
}

.Submit {
  position: absolute;
  top: 190px;
  left: 420px;
  height: 35px;
  width: 136px;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  background-color: #656AF9;
  color: white;
  font-weight: bold;
  letter-spacing: 2px;
  text-indent: 25px;
}

.fa-arrows-left-right {
  position: absolute;
  top: 200px;
  left: 437px;
  color: white;
  font-size: 17px;
}

.fa-paperclip {
  position: absolute;
  right: 510px;
  top: 195px;
  width: 20px;
  height: 20px;
  color: #778899;
  font-size: 20px;
  cursor: pointer;
}

.links {
  position: absolute;
  transform: translateX(1028px);
  cursor: pointer;
  font-size: 14px;
  top: 176px;
  color: #9298A6
}

.fa-plus {
  position: absolute;
  right: 300px;
  top: 197px;
  width: 20px;
  height: 20px;
  color: #778899;
  font-size: 20px;
  cursor: pointer;
}

.Saving {
  position: absolute;
  transform: translateX(1237px);
  cursor: pointer;
  font-size: 14px;
  top: 176px;
  color: #9298A6
}

.fa-file {
  position: absolute;
  right: 175px;
  top: 197px;
  width: 20px;
  height: 20px;
  color: #778899;
  font-size: 20px;
  cursor: pointer;
}

.Download {
  position: absolute;
  transform: translateX(1360px);
  cursor: pointer;
  top: 177px;
  font-size: 14px;
  color: #9298A6
}