*::after,
*::before {
    box-sizing: border-box;
}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background-color: #f7f9fc;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.navbar-content {
    width: 80%; /* Adjust as needed */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.navbar-logo img {
    position: absolute;
    height: 25px;
    width: 80px;
    left: 10rem;
    top: 1.8rem;
}

.navbar-search form {
    position: absolute;
    left: 18rem;
    top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-search input[type='text'] {
    padding: 8px 30px 8px 40px; /* Adjust padding to make space for the icon */
    border: none;
    border-radius: 20px; /* Round border */
    background: #ffffff url('../../images/SearchIcon.png') no-repeat 10px center; /* Path to your search icon */
    background-size:15px 15px ;
}

.navbar-search input[type='text']:focus {
    outline: none;
}

.navbar-login button {
    background-color: rgba(101, 106, 249, 0.1);
    border: none;
    color: #656AF9;
    padding: 8px 16px;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.navbar-login button:hover {
    background-color: rgb(131, 117, 255);
    /* border: 1px solid rgb(119, 104, 255); */
    color: rgb(255, 255, 255);
}

