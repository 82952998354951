*::after,
*::before,
* {
  box-sizing: border-box;
}

.button-0 {
  position: relative;
  margin-left: 160px;
  transform: translateX(5px);
  top: 257px;
  width: 40px;
  height: 40px;
  border-radius: 15px;
  border: none;
  background-color: rgba(146, 152, 166, 0.15);
  cursor: pointer;
}

.btnImage-0 {
  position: absolute;
  width: 15px;
  height: 14px;
  right: 13px;
  top: 13px;
  line-height: 12px;
}

.button-1 {
  position: relative;
  margin-left: 120px;
  transform: translateX(5px);
  top: 257px;
  width: 40px;
  height: 40px;
  border-radius: 15px;
  border: none;
  background-color: rgba(146, 152, 166, 0.15);
  cursor: pointer;
}

.btnImage-1 {
  position: absolute;
  width: 15px;
  height: 14px;
  right: 13px;
  top: 13px;
  line-height: 12px;
}

.button-2 {
  position: relative;
  margin-left: 120px;
  transform: translateX(5px);
  top: 257px;
  width: 40px;
  height: 40px;
  border-radius: 15px;
  border: none;
  background-color: rgba(146, 152, 166, 0.15);
  cursor: pointer;
}

.btnImage-2 {
  position: absolute;
  width: 15px;
  height: 14px;
  right: 13px;
  top: 13px;
  line-height: 12px;
}

.button-3 {
  position: relative;
  margin-left: 220px;
  transform: translateX(5px);
  top: 257px;
  width: 40px;
  height: 40px;
  border-radius: 15px;
  border: none;
  background-color: rgba(146, 152, 166, 0.15);
  cursor: pointer;
}

.btnImage-3 {
  position: absolute;
  width: 15px;
  height: 14px;
  right: 13px;
  top: 13px;
  line-height: 12px;
}

.button-4 {
  position: relative;
  margin-left: 220px;
  transform: translateX(5px);
  top: 257px;
  width: 40px;
  height: 40px;
  border-radius: 15px;
  border: none;
  background-color: rgba(146, 152, 166, 0.15);
  cursor: pointer;
}

.btnImage-4 {
  position: absolute;
  width: 15px;
  height: 14px;
  right: 13px;
  top: 13px;
  line-height: 12px;
}

.Main {
  position: absolute;
  top: 260px;
  left: 228px;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: rgba(146, 152, 166, 1);
  cursor: pointer;
}

.News {
  position: absolute;
  top: 260px;
  left: 390px;
  font-size: 12px;
  color: rgba(146, 152, 166, 1);
  cursor: pointer;
}

.Financial {
  position: absolute;
  top: 260px;
  left: 547px;
  font-size: 12px;
  color: rgba(146, 152, 166, 1);
  cursor: pointer;
}

.FinancialAng {
  position: absolute;
  top: 260px;
  font-size: 12px;
  left: 808px;
  color: rgba(146, 152, 166, 1);
  cursor: pointer;
}

/* ღილაკზე გადატარების დროს გალურჯდეს*/
.Main:hover {
  color: rgba(101, 106, 249, 1);
  font-weight: bold;
}

.News:hover {
  color: rgba(101, 106, 249, 1);
  font-weight: bold;
}

.FinancialAng:hover {
  color: rgba(101, 106, 249, 1);
  font-weight: bold;
}

.Financial:hover {
  color: rgba(101, 106, 249, 1);
  font-weight: bold;
}

.Similar:hover {
  color: rgba(101, 106, 249, 1);
  font-weight: bold;
}



.Similar {
  position: absolute;
  top: 260px;
  font-size: 12px;
  left: 1070px;
  cursor: pointer;
  color: rgba(146, 152, 166, 1);
}


.nav-item {
  margin-right: 45px;
  padding: 10px;
  cursor: pointer;
  color: rgba(146, 152, 166, 1);
  font-size: 14px;
  letter-spacing: 1px;
}

/* Hr Line */
.BtnHr3 {
  position: absolute;
  width: 1036px;
  left: 170px;
  top: 320px;
  border: 0.5px solid rgba(217, 219, 233, 1);
  font-weight: 0;
}

.nav {
  list-style-type: none;
  padding: 280px;
  margin: 40px;
  display: flex;
}

.nav-item:hover {
  color: rgba(101, 106, 249, 1);
}


.FinancialHr {
  position: absolute;
  left: 760px;
  top: 318px;
  width: 214px;
  height: 0px;
  padding: 2px, 0px, 1px, 0px;
  border: 1.5px solid rgba(101, 106, 249, 1);
}

.FinancialHr {
  display: none;
}

.FinancialHr2 {
  position: absolute;
  left: 210px;
  top: 318px;
  width: 100px;
  height: 0px;
  padding: 2px, 0px, 1px, 0px;
  border: 1.5px solid rgba(101, 106, 249, 1);
}

.FinancialHr2 {
  display: none;
}

.FinancialHr3 {
  position: absolute;
  left: 380px;
  top: 318px;
  width: 95px;
  height: 0px;
  padding: 2px, 0px, 1px, 0px;
  border: 1.5px solid rgba(101, 106, 249, 1);
}

.FinancialHr3 {
  display: none;
}

.FinancialHr4 {
  position: absolute;
  left: 525px;
  top: 318px;
  width: 210px;
  height: 0px;
  padding: 2px, 0px, 1px, 0px;
  border: 1.5px solid rgba(101, 106, 249, 1);
}

.FinancialHr4 {
  display: none;
}

.FinancialHr5 {
  position: absolute;
  left: 1055px;
  top: 318px;
  width: 150px;
  height: 0px;
  padding: 2px, 0px, 1px, 0px;
  border: 1.5px solid rgba(101, 106, 249, 1);
}

.FinancialHr5 {
  display: none;
}

.CaseHr {
  position: absolute;
  left: 620px;
  top: 400px;
  width: 184px;
  height: 0px;
  padding: 2px, 0px, 1px, 0px;
  border: 1.5px solid rgba(101, 106, 249, 1);
}

.CaseHr {
  display: none;
}
