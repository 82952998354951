.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    /* font-weight: bold; */
    transform:translateY(-18rem);
    /* display: none;  */
  }
  
  .tableClass {
    border-collapse: collapse;
    width: 70%;
    border: 1px solid #ececec; /* Light grey border */
    position: absolute;
    top: 9.5rem;
  }
  
  .tableClass th,
  .tableClass td {
    border: 1px solid #ccc; /* Light grey border */
    padding: 8px;
    text-align: left;
  }
  
  .tableClass th {
    background-color: #ffffff; /* Light grey background for header */
  }
  
  /* Adjust width of the first column */
  .tableClass td:first-child {
    width: 60%; /* Adjust the width as needed */
    /* background-color: pink; */
  }
  
  .tableClass th:first-child {
    color: silver;/* Adjust the width as needed */
  }
  
  .tableRowStyle {
    background-color:rgba(101, 106, 249, 0.1);
    color: #656AF9;
    /* font-weight: bold; */
  }